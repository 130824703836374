<template>
  <BasicCrud
    ref="crud"
    :title="$t('Export')"
    path="export"
    :show-new-button="false"
    custom-search-event="search"
    @search="searchFor"
    :filters="filters"
    @filter="filterChanged"
    fluid
  >
    <template v-slot:aftersearch>
      <v-switch
        v-model="exported"
        :label="$t('Exported')"
        style="margin-left:15px;margin-top:20px"
        @click="reloadData"
      ></v-switch>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="download"
            style="margin-left:10px"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Download CSV') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="update"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon v-if="!exported">mdi-database-check</v-icon>
            <v-icon v-if="exported">mdi-file-undo</v-icon>
          </v-btn>
        </template>
        <span v-if="!exported">{{ $t('Mark as exported') }}</span>
        <span v-if="exported">{{ $t('Return to not exported') }}</span>
      </v-tooltip>
    </template>

    <template v-slot:table>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="loading"
        :items-per-page="20"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000]}"
        :server-items-length="itemsLength"
      >
        <template v-slot:item.fullname="{ item }">
          <span class="text-truncate">{{ item.fullname }}</span>
        </template>

        <template v-slot:item.r.title="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.event.product.title : null }}</span>
        </template>

        <template v-slot:item.u.name="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.event.unit.name : null }}</span>
        </template>

        <template v-slot:item.e.start_at="{ item }">
          <span class="text-truncate">{{ item.voucher ? parseDateValue(item.voucher.payment.event.start_at) + ' - ' + parseDateValue(item.voucher.payment.event.finish_at) : null }}</span>
        </template>

        <template v-slot:item.c.group="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.customer.group : null }}</span>
        </template>

        <template v-slot:item.p.id="{ item }">
          {{ item.voucher ? item.voucher.payment.id.toString().padStart(9, '0') : null }}
        </template>

        <template v-slot:item.c.fullname="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.customer.fullname : null }}</span>
        </template>

        <template v-slot:item.e.id_fotop="{ item }">
          <span class="text-truncate">{{ item.voucher ? item.voucher.payment.event.id_fotop : null }}</span>
        </template>

      </v-data-table>
    </template>
  </BasicCrud>
</template>

<script>

import Vue from 'vue'
import BasicCrud from '@/components/BasicCrud'
import api from '@/services/api'
import i18n from '@/plugins/i18n.js'
import { DateTime } from "luxon"
import AppActions from '@/store/app/actions-types'
import { mapActions } from 'vuex'

export default {
  name: "Product",

  components: {
    BasicCrud
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  mounted() {
    this.loadItems()
  },

  computed: {
    status() {
      return this.filters.find(filter => filter.name == 'p.status').value
    }
  },

  data() {
    return {
      headers: [
        {
          value: "p.id",
          text: i18n.t("Payment ID")
        },
        {
          value: "c.fullname",
          text: i18n.t("Customer")
        },
        {
          value: "fullname",
          text: i18n.t("Student")
        },
        {
          value: "c.group",
          text: i18n.t("School or group")
        },
        {
          value: "r.title",
          text: i18n.t("Product")
        },
        {
          value: "u.name",
          text: i18n.t("Unit")
        },
        {
          value: "e.start_at",
          text: i18n.t("Event")
        },
        {
          value: "e.id_fotop",
          text: i18n.t("ID Fotop")
        }
      ],
      items: [],
      options: {},
      itemsLength: 20,
      loading: false,
      search: null,
      exported: false,
      filters: [
        {
          type: 'select',
          items: [],
          value: null,
          name: 'u.id',
          event: 'filter',
          label: i18n.t('Unit'),
          itemText: 'name',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'r.id',
          event: 'filter',
          label: i18n.t('Product'),
          itemText: 'title',
          itemValue: 'id'
        },
        {
          type: 'select',
          items: [],
          value: null,
          name: 'e.id',
          event: 'filter',
          label: i18n.t('Event'),
          itemText: 'title',
          itemValue: 'id'
        }
      ]
    }
  },

  methods: {
    ...mapActions('app', [
        AppActions.OPEN_APP_SUCCESS_MESSAGE
      ]
    ),

    reloadData() {
      let options = this.getFindOptions()

      api
        .find('export', options)
        .then((response) => {
          this.items = response.data.results
          this.itemsLength = response.data.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    getFindOptions() {
      this.loading = true

      let options = {...this.$route.query}

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc && this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.page = this.options.page
      options.search = this.search

      this.filters.forEach((filter) => {
        if (filter.value) {
          options['filters[' + filter.name + ']'] = filter.value
        }
      })

      options['filters[p.status]'] = 'paid'

      options['filters[v.exported]'] = this.exported ? 1 : 0

      return options
    },

    loadItems() {
      api
        .find('unit', {order: 'name', limit: 100})
        .then((response) => Vue.set(this.filters[0], 'items', response.data.results))

      api
        .find('product', {order: 'title', limit: 100})
        .then((response) => Vue.set(this.filters[1], 'items', response.data.results))

      api
        .find('event', {order: 'start_at', limit: 100})
        .then((response) => {
          let events = []

          response.data.results.forEach(evt => events.push(
            {id: evt.id, title: this.parseDateValue(evt.start_at) + ' - ' + this.parseDateValue(evt.finish_at)}
          ))

          Vue.set(this.filters[2], 'items', events)
        })
    },

    searchFor(search) {
      this.search = search
      this.reloadData()
    },

    update() {
      let options = this.getFindOptions()

      if (!this.exported) {
        options.exported = 1
      }

      api
        .find('export/update', options)
        .then(() => {
          if (this.exported) {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Items returned to not exported.'))
          } else {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Items marked as exported.'))
          }

          this.reloadData()
        })
        .catch(() => this.loading = false)
    },

    parseDateValue(dateValue) {
      return DateTime.fromISO(dateValue.replace(' ', 'T')).setLocale(i18n.locale).toLocaleString()
    },

    filterChanged() {
      this.reloadData()
    },

    download() {
      let items = []

      this.items.forEach((item) => {
        let itemValues = [
          item.fullname,
          item.email ? item.email : item.voucher.payment.customer.email,
          '',
          '',
          this.parseDateValue(item.voucher.payment.created_at),
          parseFloat(item.voucher.payment.price).toFixed(2).toString().replace('.', ','),
          'Pedido #' + item.voucher.payment.id.toString().padStart(9, '0'),
          item.voucher.payment.event.id_fotop ? item.voucher.payment.event.id_fotop : ''
        ]

        items.push(itemValues)
      })

      this.exportCSVFile(items, 'export')
    },

    exportCSVFile(items, fileTitle) {
      let jsonObject = JSON.stringify(items)
      
      let csv = this.convertToCSV(jsonObject)
      
      let exportedFilename = fileTitle + '.csv'
      
      let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename)
        return
      }

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob)

        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilename)
        link.style.visibility = 'hidden'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
      }
    },

    convertToCSV(objArray) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
      let str = ''

      for (let i = 0; i < array.length; i++) {
          let line = ''
          for (let index in array[i]) {
              if (line != '') line += ','

              line += '"' + array[i][index] + '"'
          }

          str += line + '\r\n'
      }

      return str;
    }
  }
}

</script>
