var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicCrud',{ref:"crud",attrs:{"title":_vm.$t('Export'),"path":"export","show-new-button":false,"custom-search-event":"search","filters":_vm.filters,"fluid":""},on:{"search":_vm.searchFor,"filter":_vm.filterChanged},scopedSlots:_vm._u([{key:"aftersearch",fn:function(){return [_c('v-switch',{staticStyle:{"margin-left":"15px","margin-top":"20px"},attrs:{"label":_vm.$t('Exported')},on:{"click":_vm.reloadData},model:{value:(_vm.exported),callback:function ($$v) {_vm.exported=$$v},expression:"exported"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"icon":""},on:{"click":_vm.download}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Download CSV')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.update}},'v-btn',attrs,false),on),[(!_vm.exported)?_c('v-icon',[_vm._v("mdi-database-check")]):_vm._e(),(_vm.exported)?_c('v-icon',[_vm._v("mdi-file-undo")]):_vm._e()],1)]}}])},[(!_vm.exported)?_c('span',[_vm._v(_vm._s(_vm.$t('Mark as exported')))]):_vm._e(),(_vm.exported)?_c('span',[_vm._v(_vm._s(_vm.$t('Return to not exported')))]):_vm._e()])]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":20,"options":_vm.options,"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000]},"server-items-length":_vm.itemsLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.fullname))])]}},{key:"item.r.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? item.voucher.payment.event.product.title : null))])]}},{key:"item.u.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? item.voucher.payment.event.unit.name : null))])]}},{key:"item.e.start_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? _vm.parseDateValue(item.voucher.payment.event.start_at) + ' - ' + _vm.parseDateValue(item.voucher.payment.event.finish_at) : null))])]}},{key:"item.c.group",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? item.voucher.payment.customer.group : null))])]}},{key:"item.p.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher ? item.voucher.payment.id.toString().padStart(9, '0') : null)+" ")]}},{key:"item.c.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? item.voucher.payment.customer.fullname : null))])]}},{key:"item.e.id_fotop",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.voucher ? item.voucher.payment.event.id_fotop : null))])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }